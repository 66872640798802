<template>
  <div class="container" :style="height">
    <div class="title">结算中心</div>
    <div class="buynow">
      <table border="0" cellspacing="0" class="table-recomm">
        <thead>
          <tr>
            <th>商品清单</th>
            <th>售价</th>
            <th>数量</th>
            <th>小计</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{cont.goodsName}}</td>
            <td>${{cont.goodsPrice}}</td>
            <td>1</td>
            <td>${{cont.goodsPrice}}</td>
          </tr>
        </tbody>
      </table>
      <div class="total">
        商品总额：
        <em>${{cont.goodsPrice}}</em>
      </div>
      <div class="students">
        <h3>本课采取学员推荐制，购买请填写推荐学员的手机号或者邮箱</h3>
        <div class="tel">
          <span>手机号/邮箱</span>
          <input type="text" v-model="recommendUserLong" />
          <a @click="goStudents">寻找推荐人</a>
          <!-- <router-link :to="{path:'/students'}">寻找推荐人</router-link> -->
        </div>
        <div class="tel">
          <span>验证码</span>
          <input type="text" v-model="code" />
          <a
            @click="sendmessage"
            :class="{active:time>0}"
            v-loading.fullscreen.lock="fullscreenLoading"
          >{{sendMessageName}}</a>
        </div>
      </div>
      <div class="agreement">
        <span class="left">
          <el-checkbox v-model="checked">我已阅读并接受</el-checkbox>
          <router-link to="/about">《售后政策》</router-link>
        </span>
        <span class="right">
          应付金额：
          <em class="color-FF6C6C ft-18">${{cont.goodsPrice}}</em>
        </span>
      </div>
      <a class="common-btn buybtn" @click="buyClick">确认购买</a>
    </div>
  </div>
</template>
<script>
// 获取某一个课程的接口
import { selectOne, insert, getCodeCourse } from "@/assets/js/api";
export default {
  name: "buynow",
  data() {
    return {
      height: { height: "" },
      checked: false,
      id: "",
      cont: "",
      recommendUserLong: "",
      code: "",
      sendMessageName: "发送验证码",
      time: 0,
      fullscreenLoading: false
    };
  },
  methods: {
    // 可视区域高度
    getHight() {
      this.height.height = window.innerHeight - 180 + "px";
    },
    coursecont: function() {
      selectOne({
        id: this.id
      }).then(result => {
        this.cont = result.sysGoods;
        localStorage.setItem("paymoney", result.sysGoods.goodsPrice);
      });
    },

    // 确认购买
    buyClick() {
      if (this.recommendUserLong == "") {
        this.$message.error("推荐人手机号或邮箱不能为空");
        return;
      }
      if (this.checked == false) {
        this.$message.error("请阅读售后政策");
        return;
      }
      // localStorage.setItem("recommendUserLong", this.recommendUserLong);

      let ruleForm = {
        goodsId: this.id,
        recommendUserLong: this.recommendUserLong,
        number: 1,
        code: this.code
      };
      insert(ruleForm).then(result => {
        if (result.code == 0) {
          this.$router.push({
            path: "./settlement",
            query: { id: result.appUserCourse.id }
          });
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    // 倒计时60s
    get() {
      this.time--;
      if (this.time == 0) {
        clearInterval(this.timer);
        this.sendMessageName = "重新发送";
        return;
      }
      this.sendMessageName = this.time + "s重新发送";
    },
    sendmessage: function() {
      if (this.time == 0) {
        if (this.recommendUserLong == "") {
          this.$message.error("请先输入手机号");
          return;
        }
        this.fullscreenLoading = true;
        getCodeCourse({
          value: this.recommendUserLong,
          goodsId: this.id
        }).then(result => {
          if (result.code == 0) {
            this.time = 120;
            this.timer = setInterval(this.get, 1000);
            this.$message({
              message: "验证码发送成功，请查收短信",
              type: "success"
            });
            this.fullscreenLoading = false;
          } else {
            this.$message.error(result.msg);
            this.fullscreenLoading = false;
          }
        });
      }
    },
    // 寻找推荐人
    goStudents: function() {
      if (localStorage.getItem("token")) {
        window.open("http://www.tertioenglish.com/partners");
        // this.$router.push({
        //     path: "./students",
        // });
      } else {
        this.$message.error("请先登录");
      }
    }
  },
  mounted: function() {
    this.coursecont();
  },
  created() {
    this.id = localStorage.getItem("courseId");
    this.getHight();
    window.addEventListener("resize", this.getHight);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHight);
  }
};
</script>
